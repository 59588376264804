import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import MobileMenu from "../components/mobile-menu/MobileMenu";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import MobileBtn from "../components/mobile-menu/MobileBtn";

import logo from "../assets/img/vivenLogoFinal.png";
const Menu = () => {
  const triggerSearch = () => {
    const offcanvasMobileMenu = document.querySelector(".search_icon_inr");
    offcanvasMobileMenu.classList.toggle("active");
  };

  return (
    <div className="menu_area">
      {/* Start: header navigation */}
      <div style={{backgroundColor:'#041e42'}}  className="navigation">
        <div
          style={{ display: "flex", alignItems: "center"}}
          className="container"
        >
          <div className="logo">
            <Link to={process.env.PUBLIC_URL + "/"}>
              <img src={logo} alt="" />
            </Link>
          </div>

          <div style={{marginLeft: 'auto'}} className="meun_wrp">
            <Navbar expand="lg" sticky="top" id="navigation">
              <Nav className="mr-auto">
                <ul>
                  <li  className="navl">
                    <Link style={{fontSize: '23px', fontWeight: 'bold'}} to={"/"}>Home </Link>
                  </li>
                  <li className="navl">
                    <Link
                    style={{fontSize: '23px', fontWeight: 'bold'}} 
                      to={{
                        pathname: "/", // Your home page
                        search: "?scrollTo=team-section", // Query parameter with the anchor point
                      }}
                    >
                      About us
                    </Link>
                  </li>
                  <li >                
                    <Link className="navl" style={{fontSize: '23px', fontWeight: 'bold'}}  to="/services">Services </Link>
                  </li>

                  <li className="navl">
                    <Link className="navl" style={{fontSize: '23px', fontWeight: 'bold'}}  to="/contact">Contact</Link>
                  </li>
                </ul>
              </Nav>
            </Navbar>
          </div>

          {/* Mobile Menu */}

          <MobileBtn />

          <MobileMenu />

          {/* End:  Mobile Menu */}

          {/* Start: Cart  */}

          {/* End: Cart  */}
        </div>
        {/* container */}
      </div>
      {/* End: header navigation */}
    </div>
  );
};

export default Menu;
