import React, { Component } from "react";
import { ExternalLink } from "react-external-link";

class Team extends Component {
  state = {
    heading: "Meet Our Strength",
    subHeading: "Our Team Members",
  };

  render() {
    const teamlistArray = [
      {
        image: "team3.jpg",
        name: "Venkatramanan Chinnasamy",
        designation: "Managing Director",
        linkedIn: "https://www.linkedin.com/in/venkat-aviation/",
        about:
          "Venkatramanan Chinnasamy (Venkat), is based in Jakarta, Indonesia. With a professional background spanning more than 12 years in Aviation Industry, he possesses a wealth of expertise in the aviation sector. His skill set covers a broad spectrum of responsibilities including flight operations, project management, and aircraft sales and leasing. His proficiency is further underscored by his substantial familiarity with a range of international enterprises globaly and specifically evident  in Southeast Asia.",
      },
      {
        image: "team1.jpg",
        name: "Vignesh Narasimhan",
        designation: "Executive Director",
        linkedIn: "https://www.linkedin.com/in/vignesh-narasimhan-a57b0483/",
        about:
          "Vignesh Narasimhan resides in London, United Kingdom, bringing over 15 years of proficient experience in the field of Information Technology. Throughout his career, he has held a diverse range of managerial roles, encompassing responsibilities such as Supplier Development, Finance Investments, and Logistics. His professional journey includes substantial exposure to the global landscape, having worked extensively with clients hailing from nations such as India, Belgium, the United Kingdom, and the Middle East, specifically Oman and Saudi Arabia.",
      },

      {
        image: "team2.jpg",
        name: "Kemas Antoni Azuar",
        designation: "General Manager",
        about:
          "Kemas Antoni Azuar, an Indonesian national residing in Jakarta, boasts a career spanning more than 15 years in the aviation industry. His extensive expertise encompasses various facets of the field, including logistics, maintenance, flight operations, as well as aircraft sales and leasing.",
      },
    ];
    //   <img src={`assets/images/${valu.image}`} alt="team" />

    const teamlistMap = teamlistArray.map((valu, i) => {
      return (
        <div className="col-md-4 col-sm-12" key={i}>
          <div
            style={
              i === 2
                ? { marginTop: "0px", height: "520px" }
                : i === 1
                ? { marginTop: "40px" }
                : {}
            }
            className="team_wrp"
          >
            <div className="team_img">
              <h1 style={{ marginBottom: "0px" }}></h1>
            </div>
            <div className="team_info">
              <h4>{valu.name}</h4>
              <strong>{valu.designation}</strong>
              <p>{valu.about}</p>
              <ul className="social list-inline">
                {valu.linkedIn && (
                  <li>
                    <ExternalLink href={valu.linkedIn}>
                      <i className="fa fa-linkedin"></i>
                    </ExternalLink>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      );
    });

    return (
      <section
        className="team-section"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/assets/images/bg_3.jpg"
          })`,
        }}
      >
        <div className="container">
          <div className="base-header">
            <small>{this.state.subHeading} </small>
            <h3>{this.state.heading}</h3>

            <p style={{ marginTop: "40px" }}>
              Empowered by a resolute leadership team with a clear roadmap,
              we're primed to realize our strategy and achieve sustainable
              growth. Our unwavering dedication to excellence drives us forward,
              while responsible practices ensure lasting success.
            </p>
          </div>
          <div className="row">{teamlistMap}</div>
        </div>
      </section>
    );
  }
}

export default Team;
