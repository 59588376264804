import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-reveal";

import img6 from "../assets/img/imgPart.jpg";
import img1 from "../assets/img/viven-asset-3.avif";
import img2 from "../assets/img/viven-asset-4.avif";

class ServiceContent extends Component {
  render() {
    const serviceListArray = [
      {
        image: img1,
        title: "Aircraft charter",
        description:
          "Fly your yay: Experience the ultimate freedom with uur aircraft charter services. Tailored to your needs, we take you wherever your dreams soar.",
        link: "services",
      },
      {
        image: img2,
        title: "Aircraft sales and leasing",
        description:
        "Unlock your skies: Our team specialized in aircraft sales and leasing, catering to a wide range of aircraft sizes.",
        link: "services",
      },
      {
        image: img6,
        title: "Aircraft parts and engines",
        description:"Keeping you airborne: Discover top-quality aircraft parts, engines and rotables. Our extensive inventory ensures smooth flights and uncompromised safety.",
        link: "services",
      },
    ];

    const serviceListtMap = serviceListArray.map((valu, i) => {
      return (
        <div className="col-md-4 col-sm-12" key={i}>
          <div className="service-item">
            <div className="img_serv">
              <Link to={`${valu.link}`}>
                <img
                  style={{ height: "250px", width: "100%" }}
                  src={`${valu.image}`}
                  alt="service"
                />
              </Link>
            </div>
            <div className="service_text" style={{ height: "200px" }}>
              <Link to={`${valu.link}`}>
                {" "}
                <h4>{valu.title}</h4>
              </Link>
              <p>
                {valu.description} <Link to={valu.link}>read more</Link>
              </p>
            </div>
            <Link to={`${valu.link}`} className="serv_link">
              <i className="icon-glyph-40"></i>
            </Link>
          </div>
        </div>
      );
    });

    return (
      <section className="service-section">
        <div className="animate_icon">
          <div className="animate_item animate_item1 bounce_animate">
            <img src="assets/images/animate_icon1.png" alt="" />
          </div>
          <div className="animate_item animate_item2 bounce_animate">
            <img src="assets/images/animate_icon2.png" alt="" />
          </div>
          <div className="animate_item animate_item3 bounce_animate">
            <img src="assets/images/animate_icon3.png" alt="" />
          </div>
          <div className="animate_item animate_item4 bounce_animate">
            <img src="assets/images/animate_icon4.png" alt="" />
          </div>
        </div>
        <div className="container">
          {/* Heading */}
          <div className="base-header">
            <small>What We Offer</small>
            <h3>Service we offer</h3>
          </div>
          {/* End: Heading */}

          <div className="row">
            <Fade bottom>{serviceListtMap}</Fade>
          </div>
        </div>
      </section>
    );
  }
}

export default ServiceContent;
