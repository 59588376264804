import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Gallery from "./pages/Gallery";
import GalleryTwo from "./pages/GalleryTwo";
import Contact from "./pages/Contact";
import SingleService from "./pages/SingleService";
import Team from "./pages/Team";
import Shop from "./pages/Shop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SingleShop from "./pages/SingleShop";
import BlogPageTwo from "./pages/BlogPageTwo";
import BlogPageOne from "./pages/BlogPageOne";
import SingleBlog from "./pages/SingleBlog";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import NotFound from "./pages/NotFound";

function App() {

  return (
    <Router>
      <ScrollToTop />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        {/* 
        // <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery-two" element={<GalleryTwo />} />
        <Route path="/single-services" element={<SingleService />} />
        <Route path="/team" element={<Team />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/single-shop" element={<SingleShop />} />
        <Route path="/blog" element={<BlogPageOne />} />
        <Route path="/blog-two" element={<BlogPageTwo />} />
        <Route path="/single-blog" element={<SingleBlog />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
      */}
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
