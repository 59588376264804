import React from "react";
import { AiOutlineAntDesign, AiOutlineApi } from "react-icons/ai";
import img from '../assets/img/viven-asset-7.webp'

const ChooseUs = () => {
  return (
    <section className="whychose-section" style={{width: '100%'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-sm-12">
            <div
              className="whychose_bg"
              style={{
                backgroundSize: "cover",
                backgroundImage: `url(${img})`,
              }}
            ></div>
          </div>
          <div className="col-lg-7 col-sm-12 my-auto">
            <div className="whychose_wrp">
              {/* Start:  Header Section */}
              <div className="base-header base_header_left">
                <small>What We do</small>
                <h3> Why Choose Us </h3>
              </div>
              {/* End: Header Section */}
              <div className="special_ser_item">
                <div className="special_ser_icon">
                  <AiOutlineAntDesign />
                </div>
                <h4>Integrity</h4>
                <p>
                  With Unwavering Integrity at Our Core, Viven Aviation is your
                  trusted partner in the aviation industry. Count on us for
                  transparent and reliable services that take flight safety to
                  new heights.
                </p>
              </div>
              <div className="special_ser_item">
                <div className="special_ser_icon">
                  <AiOutlineApi />
                </div>
                <h4>Innovation</h4>
                <p>
                  Revolutionizing Flight with Innovation: Our team sets new
                  standards, delivering sky-high advancements for an
                  extraordinary journey through the clouds.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* row */}
      </div>
      {/* container */}
    </section>
  );
};

export default ChooseUs;
