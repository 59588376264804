import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import "swiper/css";
import img from '../assets/img/viven-asset-1.png'

import { Fade, Slide } from "react-reveal";

class Slides extends Component {
  state = {
    scrollPosition: 0,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      scrollPosition: window.scrollY,
    });
  };

  // https://img.freepik.com/premium-photo/modern-luxury-private-jet-flies-isolated-white-background_527900-1979.jpg

  render() {
    let slideImages = [
      {
        img: img,
        smallTitle: "Cleaning Services",
        title: "Navigating the skies with Viven Aviation : Experience our Advanced Aviation solutions",
        description:
          "Discover Viven Aviation - Your all-in-one aviation hub. From aircraft charter and aircraft sales to leasing, aircraft financing and parts, trust our expertise. Elevate your aviation ventures with us today.",
      },
    ];

    const properties = {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      watchSlidesVisibility: true,
      effect: "slide",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      autoplay: {
        delay: 1000,
      },
    };

    let ImageGalleryDataList = slideImages.map((val, i) => {
      const backgroundPositionX = this.state.scrollPosition / 15;

      return (
        <div
          className="single_slider slide_bg_1"
          style={{
            backgroundColor: "white",
            backgroundImage: `url(${val.img})`,
            backgroundPosition: `${35+backgroundPositionX}% 300px`,
            
          }}
          key={i}
        >
          <div className="slider_item_tb">
            <div className="slider_item_tbcell">
              <div className="container">
                <div className="row">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      height: "80vh",
                    }}
                    className="col-lg-6 col-sm-12"
                  >
                    <Slide bottom>
                      <div style={{marginTop:'-100px'}}>
                        <h2 className="landingTitle">{val.title}</h2>
                        <p style={{ color: "black" }}>{val.description}</p>
                      </div>
                    </Slide>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="slides_wrapper">
        <div className="slider_home">
          <Swiper {...properties}>{ImageGalleryDataList}</Swiper>
        </div>
      </div>
    );
  }
}

export default Slides;
