import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./assets/icon/custom-icons/css/custom-icons.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import ReactGA from 'react-ga4'


ReactGA.initialize('G-EDESQ7Y2WX');


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);